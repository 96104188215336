import { setRemoteDefinitions } from '@web-app-frontend/load-remote-module'

Promise.resolve({
  'landing-page': process.env.NX_PUBLIC_LANDING_PAGE_REMOTE_URL,
  onboarding: process.env.NX_PUBLIC_ONBOARDING_REMOTE_URL,
  'earnin-app': process.env.NX_PUBLIC_EARNIN_APP_REMOTE_URL,
  'mobile-shell': process.env.NX_PUBLIC_MOBILE_SHELL_REMOTE_URL,
  'upcoming-bills-subscriptions': process.env.NX_PUBLIC_UPCOMING_BILLS_SUBSCRIPTIONS_REMOTE_URL
})
  .then((definitions) => setRemoteDefinitions(definitions))
  .then(() => import('./bootstrap').catch((err) => console.error(err)))
