type ResolveRemoteUrlFunction = (remoteName: string) => string | Promise<string>

// eslint-disable-next-line camelcase
declare const __webpack_init_sharing__: (scope: 'default') => Promise<void>
// eslint-disable-next-line camelcase
declare const __webpack_share_scopes__: { default: unknown }

let resolveRemoteUrl: ResolveRemoteUrlFunction

export function setRemoteUrlResolver(_resolveRemoteUrl: ResolveRemoteUrlFunction) {
  resolveRemoteUrl = _resolveRemoteUrl
}

let remoteUrlDefinitions: Record<string, string>

export function setRemoteDefinitions(definitions: Record<string, string>) {
  remoteUrlDefinitions = definitions
}

const remoteModuleMap = new Map<string, unknown>()
const remoteContainerMap = new Map<string, unknown>()

export async function loadRemoteModule(remoteName: string, moduleName: string) {
  const remoteModuleKey = `${remoteName}:${moduleName}`
  if (remoteModuleMap.has(remoteModuleKey)) {
    return remoteModuleMap.get(remoteModuleKey)
  }

  const container = remoteContainerMap.has(remoteName)
    ? remoteContainerMap.get(remoteName)
    : await loadRemoteContainer(remoteName)

  const factory = await container.get(moduleName)
  const Module = factory()

  remoteModuleMap.set(remoteModuleKey, Module)

  return Module
}

function loadModule(url: string) {
  return import(/* webpackIgnore:true */ url).catch(() => {
    return null
  })
}

let initialSharingScopeCreated = false

async function loadRemoteContainer(remoteName: string) {
  if (!resolveRemoteUrl && !remoteUrlDefinitions) {
    throw new Error(
      'Call setRemoteDefinitions or setRemoteUrlResolver to allow Dynamic Federation to find the remote apps correctly.',
    )
  }

  if (!initialSharingScopeCreated) {
    initialSharingScopeCreated = true
    await __webpack_init_sharing__('default')
  }

  const remoteUrl = remoteUrlDefinitions
    ? remoteUrlDefinitions[remoteName]
    : await resolveRemoteUrl(remoteName)

  const containerUrl = `${remoteUrl}`
  try {
    const container = await loadModule(containerUrl)
    if (!container) {
      throw new Error(`Failed to fetch dynamically imported module: ${containerUrl}`)
    }
    // eslint-disable-next-line camelcase
    await container.init(__webpack_share_scopes__.default)

    remoteContainerMap.set(remoteName, container)
    return container
  } catch (error: unknown) {
    if (
      error instanceof Error &&
      error.message.indexOf('Failed to fetch dynamically imported module: ') > -1
    ) {
      // retry 3 times with 1 seconds delay
      for (let i = 0; i <= 2; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        const url = new URL(
          error.message
            .replace('Failed to fetch dynamically imported module: ', '')
            .replace(/^\/\//, `${window.location.protocol}//`)
            .trim(),
        )
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set('t', `${Date.now()}`)
        console.warn(`retrying import ${url.href} round ${i + 1}`)
        try {
          const tryContainer = await loadModule(url.href)
          if (!tryContainer) {
            throw new Error(`Failed to load container for remote ${remoteName}`)
          }
          // eslint-disable-next-line camelcase
          await tryContainer.init(__webpack_share_scopes__.default)

          remoteContainerMap.set(remoteName, tryContainer)
          return tryContainer
        } catch (e) {
          console.error(`failed to import ${url.href} round ${i + 1}`, { error: e })
        }
      }
    }
    throw error
  }
}
